export const GODPARENTS_BY_ID = {
    "fbe5819e-d712-42f6-bfbb-157f18c35e9b": {
        godfather: "Rodrigo",
        godmother: "Mayara"
    },
    "04924ac2-97c8-4e86-958a-4671c6ecc161": {
        godfather: "Vinicius",
        godmother: "Gabriele"
    },
    "1ee18cb8-356f-4b80-98e2-0921194a73c2": {
        godfather: "Lorena",
        godmother: "Vladimir"
    },
    "d8fbbeda-7f38-4a75-924f-1a4e4142a4a4": {
        godfather: "Patrick",
        godmother: "Tais"
    },
    "39e7b989-19c6-4b41-acb4-10dd2c9bd25f": { 
        godfather: "Matheus",
        godmother: "Lis"
    },
    "9d6f575b-6d49-4cd0-9b3d-f1e20d9eeb7e": {
        godfather: "Marlon",
        godmother: "Manuelle"
    },
    "f4460400-e32c-46cb-b76e-3e65e7fb84f2": {
        godfather: "Manuel",
        godmother: "Camilla"
    },
    "719c3f54-c046-4898-ac5d-125e20f35b9e": {
        godfather: null,
        godmother: "Júlia",
    },
    "8f6ceb45-0579-445e-a469-87a971fa710a": {
        godfather: null,
        godmother: "Aline",
    },
    "cc8b34fd-4994-44cb-9673-5affc50234e6": {
        godfather: "Gabriel",
        godmother: null,
    },
    "26d39ae6-ea3c-4b85-8d6e-121be2c34ea1": {
        godfather: null,
        godmother: "Lorena",
    },
    "f4a80c70-2172-4603-a272-2bfba59f78c3": {
        godfather: "Leonardo",
        godmother: null,
    },
}
